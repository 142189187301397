.dialog {
  --dialog-bg: #fff;
  --dialog-color: var(--color-regular);
  --dialog-border-width: 0;
  --dialog-width: 650px;
  --transition-duration: 0.3s;
  padding: 0;
  background-color: var(--dialog-bg);
  color: var(--dialog-color);
  border-width: var(--dialog-border-width);
  border-radius: var(--radius-main);
  inline-size: max(90vh, var(--dialog-width));
  margin: 11rem auto 0;
}
.dialog::backdrop {
  background: rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}
.dialog[open] {
  animation: dialog-show var(--transition-duration) ease normal;
}

.dialog-close {
  position: absolute;
  right: 0.5rem;
  top: 0;
  font-size: 1.625rem;
  font-weight: 300;
  color: var(--dialog-color);
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  transition: all --transition-duration ease;
  padding: 0.5rem;
}

.dialog-container {
  overflow: hidden;
  text-align: center;
}

.dialog-header {
  position: relative;
  padding: 1rem;
}

.dialog-content {
  text-align: center;
}

@keyframes dialog-show {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media print {
  .dialog {
    position: static;
    margin: 2cm auto;
    border: 1px solid #000;
  }
}